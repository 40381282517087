import React from 'react'
import { useTranslation } from 'react-i18next'
import { InlineNotification, InlineNotificationType } from '@gbg/gbgcomponentlibrary_react'
import { NAMESPACE as tNamespace } from './Error.intl'
import { FlowRepsonse, FlowStatus } from '../../common/auth/auth.types'

export interface ErrorProps {
  flow: FlowRepsonse
  code?: string
  message?: string
}

function getAccountLockedOutResetTime(secondsUntilUnlock: number) {
  const date = new Date()
  date.setSeconds(date.getSeconds() + secondsUntilUnlock)
  return date.toLocaleTimeString()
}

function getAccountLockedOutError(t: Function, secondsUntilUnlock: number) {
  const timeToUnlock = getAccountLockedOutResetTime(secondsUntilUnlock)
  return (
    <>
      <hr />
      <div>
        <p>
          <strong>{t('[error] account locked')}</strong>
        </p>
        <p>
          {t('[error] lockout retry 1')} <strong>{timeToUnlock}</strong>, {t('[error] lockout retry 2')}
        </p>
      </div>
    </>
  )
}

export const Error: React.FC<ErrorProps> = ({ flow }) => {
  const { t } = useTranslation(tNamespace)
  return (
    <>
      <InlineNotification type={InlineNotificationType.Error}>
        <p>{t('[error] unexpected')}</p>
        {flow?.error ? (
          <>
            {flow.error.code === FlowStatus.PASSWORD_LOCKED_OUT ? (
              getAccountLockedOutError(t, flow.error.secondsUntilUnlock as number)
            ) : (
              <>
                <h5 className="m-b-0">{t('[error] details')}</h5>
                <pre className="m-b-0" style={{ whiteSpace: 'break-spaces' }}>
                  {JSON.stringify(flow.error, null, 2)}
                </pre>
              </>
            )}
          </>
        ) : null}
      </InlineNotification>
    </>
  )
}
