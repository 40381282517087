import { AnyAction, createAsyncThunk, createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

import { FlowRepsonse, FlowStatus, Flow } from './auth.types'
import {
  getFlow,
  signOn,
  forgotPassword,
  recoverUserPassword,
  changeUserPassword,
  lookupUser,
  resetFlow,
  sendRecoveryCode,
} from './authAPI'

export interface AuthState {
  flow: FlowRepsonse | null
  status: 'idle' | 'loading' | 'failed'
  message: { isError: boolean; content: any; data?: any }
  isAuthenticated: boolean
}

const initialState: AuthState = {
  flow: null,
  status: 'idle',
  message: { isError: false, content: null },
  isAuthenticated: false,
}

const handleError =
  (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, state: RootState | null = null) =>
  (e: any) => {
    const errorCode = e?.response?.data?.details?.[0]?.code
    let message = e?.response?.data?.details[0].message
    switch (errorCode) {
      case FlowStatus.INVALID_CREDENTIALS:
        message = 'Incorrect username or password. Please try again.'
        break
      case FlowStatus.PASSWORD_LOCKED_OUT: {
        const secondsUntilUnlock = e?.response?.data?.details?.[0]?.innerError?.secondsUntilUnlock
        const timeUntilUnlockMsg =
          secondsUntilUnlock > 60 ? `${Math.floor(secondsUntilUnlock / 60)} minutes` : `${secondsUntilUnlock} seconds`
        message = `Too many unsuccessful sign-on attempts. Your account will unlock in ${timeUntilUnlockMsg}.`
        break
      }
      case FlowStatus.INVALID_VALUE: {
        const errorTarget = e?.response?.data?.details?.[0]?.target
        if (errorTarget == 'recoveryCode') {
          message = 'Incorrect recovery code. Please try again.'
        } else if (errorTarget == 'newPassword') {
          const unsatisfiedServerRequirements =
            e?.response?.data?.details?.[0]?.innerError?.unsatisfiedRequirements ?? []
          const flow = new Flow(state?.auth.flow as FlowRepsonse)
          // if there are multiple server validation fails, show just one
          const failedReq = unsatisfiedServerRequirements[0]

          message = getServerValidatedRequirementMessage(failedReq, flow.getPasswordPolicy())
        } else if (errorTarget == 'username') {
          message = 'Username did not match. Please click on back button and try again.'
        }
        break
      }
    }

    dispatch(
      authSlice.actions.unrecoverableError({
        error: message,
      }),
    )
  }

export const getServerValidatedRequirementMessage = (failedReq: string, passwordPolicy: any) => {
  switch (failedReq) {
    case 'history': {
      const historyCount = passwordPolicy?.history?.count

      // Fallback if for whatever reason we cannot load history.count
      if (historyCount === null) {
        return 'Password must not be similar to your prevous passwords'
      }

      return `Password cannot be the same or similar to your previous ${historyCount} passwords.`
    }
    case 'excludesProfileData':
      return 'Password cannot contain information from your user profile.'
    case 'notSimilarToCurrent':
      return 'Password cannot be similar to your current password.'
    case 'excludesCommonlyUsed':
      return 'Password must not be a commonly used password.'
    case 'minComplexity':
      return 'Password does not meet minimum complexity requirements.'
    default:
      return 'Password does not meet requirements.'
  }
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const getFlowAsync = createAsyncThunk(
  'auth/getFlow',
  async ({ environmentId, flowId }: { environmentId: string; flowId: string }, { dispatch }) => {
    try {
      const response = await getFlow(environmentId, flowId)
      dispatch(
        authSlice.actions.updateFlow({
          flow: response,
        }),
      )
    } catch (e: any) {
      handleError(dispatch)(e)
    }
  },
)

export const resetSessionAsync = createAsyncThunk(
  'auth/reset-session',
  async ({ apiPath }: { apiPath: string }, { dispatch }) => {
    try {
      const response = await resetFlow(apiPath)
      dispatch(
        authSlice.actions.updateFlow({
          flow: response,
          isAuthenticated: true,
        }),
      )
    } catch (e: any) {
      handleError(dispatch)(e)
    }
  },
)

export const resendRecoveryCodeAsync = createAsyncThunk(
  'auth/resendCode',
  async ({ apiPath }: { apiPath: string }, { dispatch }) => {
    try {
      const response = await sendRecoveryCode(apiPath)
      dispatch(
        authSlice.actions.updateFlow({
          flow: response,
          isAuthenticated: true,
        }),
      )
    } catch (e: any) {
      handleError(dispatch)(e)
    }
  },
)

export const provideUsernameAsync = createAsyncThunk(
  'auth/username',
  async ({ apiPath, username }: { apiPath: string; username: string }, { dispatch }) => {
    try {
      const response = await lookupUser(apiPath, username)
      dispatch(
        authSlice.actions.updateFlow({
          flow: response,
          isAuthenticated: true,
        }),
      )
    } catch (e: any) {
      handleError(dispatch)(e)
    }
  },
)

export const signOnAsync = createAsyncThunk(
  'auth/signOn',
  async ({ apiPath, username, password }: { apiPath: string; username: string; password: string }, { dispatch }) => {
    try {
      const response = await signOn(apiPath, username, password)
      dispatch(
        authSlice.actions.updateFlow({
          flow: response,
          isAuthenticated: true,
        }),
      )
    } catch (e: any) {
      handleError(dispatch)(e)
    }
  },
)

export const changeUserPasswordAsync = createAsyncThunk(
  'auth/changeUserPassword',
  async (
    {
      apiPath,
      currentPassword,
      newPassword,
    }: {
      apiPath: string
      currentPassword: string
      newPassword: string
    },
    { dispatch, getState },
  ) => {
    try {
      const response = await changeUserPassword(apiPath, currentPassword, newPassword)
      dispatch(
        authSlice.actions.updateFlow({
          flow: response,
        }),
      )
    } catch (e: any) {
      handleError(dispatch, getState() as RootState)(e)
    }
  },
)

export const recoverUserPasswordAsync = createAsyncThunk(
  'auth/recoverUserPassword',
  async (
    { apiPath, recoveryCode, newPassword }: { apiPath: string; recoveryCode: string; newPassword: string },
    { dispatch, getState },
  ) => {
    try {
      const response = await recoverUserPassword(apiPath, recoveryCode, newPassword)
      dispatch(
        authSlice.actions.updateFlow({
          flow: response,
          isAuthenticated: false,
          message: 'You successfully recovered your password, ' + response._embedded?.user?.username,
        }),
      )
    } catch (e: any) {
      handleError(dispatch, getState() as RootState)(e)
    }
  },
)

export const forgotPasswordAsync = createAsyncThunk(
  'auth/forgotPassword',
  async (details: { apiPath: string; username: string }, { dispatch }) => {
    try {
      const response = await forgotPassword(details.apiPath, details.username)
      dispatch(
        authSlice.actions.updateFlow({
          flow: response,
        }),
      )
    } catch (e: any) {
      handleError(dispatch)(e)
    }
  },
)

export const authSlice = createSlice({
  name: 'counter',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateFlow: (
      state,
      action: PayloadAction<{
        message?: string
        flow: FlowRepsonse
        isAuthenticated?: boolean
      }>,
    ) => {
      state.message = { content: action.payload.message, isError: false }
      state.isAuthenticated = action.payload.isAuthenticated ?? false
      state.flow = action.payload.flow
      if (action.payload.flow.status == FlowStatus.EXTERNAL_AUTHENTICATION_REQUIRED) {
        if (action.payload.flow._embedded?.identityProvider?._links?.authenticate?.href) {
          window.location.href = action.payload.flow._embedded.identityProvider._links.authenticate.href
        }
      }
    },
    unrecoverableError: (
      state,
      action: PayloadAction<{
        error: string
        data?: any
      }>,
    ) => {
      state.message = { isError: true, content: action.payload.error, data: action.payload.data }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(recoverUserPasswordAsync.pending, state => {
        state.status = 'loading'
      })
      .addCase(recoverUserPasswordAsync.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(resendRecoveryCodeAsync.pending, state => {
        state.status = 'loading'
      })
      .addCase(resendRecoveryCodeAsync.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(forgotPasswordAsync.pending, state => {
        state.status = 'loading'
      })
      .addCase(forgotPasswordAsync.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(getFlowAsync.pending, state => {
        state.status = 'loading'
      })
      .addCase(getFlowAsync.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(signOnAsync.pending, state => {
        state.message.isError = false
        state.status = 'loading'
      })
      .addCase(signOnAsync.fulfilled, (state, action) => {
        state.status = 'idle'
      })
  },
})

//export const {} = authSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFlow = (state: RootState) => state.auth.flow
export const selectMessage = (state: RootState) => state.auth.message
export const selectIsLoading = (state: RootState) => state.auth.status === 'loading'
export const selectLinkUrl = (key: string) => (state: RootState) => state.auth.flow?._links?.[key]?.href ?? ''

export const { unrecoverableError } = authSlice.actions
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (dispatch, getState) => {
//   const currentValue = selectCount(getState())
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount))
//   }
// }

export default authSlice.reducer
