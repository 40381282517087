declare global {
  interface Window {
    env: any
  }
}

type EnvType = {
  REACT_APP_IDP_AUTH_API_BASE_URL: string
  REACT_APP_IDP_LOGOUT_BASE_URL: string
}
export const env: EnvType = { ...process.env, ...window.env }
