import en from './locales/en.json'
import fr from './locales/fr.json'

export const NAMESPACE = 'LocationLayout'

const translations = {
  en: {
    LocationLayout: en,
  },
  fr: {
    LocationLayout: fr,
  },
}

export default translations
