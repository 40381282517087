import React from 'react'
import schema from './schema'
import { useTranslation } from 'react-i18next'
import styles from './RecoveryCode.module.css'
import { Button, ErrorText, FormGroup, Label, Password, Policy, Text } from '@gbg/gbgcomponentlibrary_react'
import { NAMESPACE as tNamespace } from './RecoveryCode.intl'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  selectIsLoading,
  recoverUserPasswordAsync,
  selectLinkUrl,
  resendRecoveryCodeAsync,
} from '../../common/auth/authSlice'
import { useFormik } from 'formik'
import { PasswordInput } from '../password/PasswordInput'

export const RecoveryCode: React.FC = () => {
  const { t } = useTranslation(tNamespace)
  const recoveryURl = useAppSelector(selectLinkUrl('password.sendRecoveryCode'))

  const dispatch = useAppDispatch()
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    touched,
    values, // use this if you want controlled components
    errors,
  } = useFormik({
    initialValues: {
      recoveryCode: '',
      password: '',
      passwordConf: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      const { recoveryCode, password } = values
      dispatch(
        recoverUserPasswordAsync({
          apiPath: recoveryURl,
          recoveryCode,
          newPassword: password,
        }),
      )
    },
  })
  const isLoading = useAppSelector(selectIsLoading)
  const resendURl = useAppSelector(selectLinkUrl('password.sendRecoveryCode'))

  const handleResend = () => {
    dispatch(
      resendRecoveryCodeAsync({
        apiPath: resendURl,
      }),
    )
  }

  return (
    <div className={styles.container}>
      <h1 className="heading-large m-t-0">{t('[recovery] title')}</h1>
      <p className="paragraph-medium">{t('[recovery] intro')}</p>
      <p>
        <a
          href="#"
          onClick={() => {
            handleResend()
          }}
        >
          {t('[recovery] resend')}
        </a>
      </p>
      <form onSubmit={handleSubmit} className={styles.form}>
        <FormGroup className="m-m-t-4 m-m-b-4">
          <Label htmlFor="recovery-code">{t('[recovery] code label')}</Label>
          <Text
            id="recovery-code"
            name="recoveryCode"
            error={touched.recoveryCode && errors.recoveryCode}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('[recovery] code placeholder')}
            aria-label="recovery-code"
          />
          {touched.recoveryCode && errors.recoveryCode ? <ErrorText>{t('[recovery] code error')}</ErrorText> : null}
        </FormGroup>
        <PasswordInput
          label={t('[recovery] password label')}
          errorText={t('[recovery] password error')}
          placeholder={t('[recovery] password placeholder')}
          {...{ handleBlur, handleChange, password: values.password, touched, errors }}
        />
        <FormGroup className="m-m-t-4 m-m-b-4">
          <Label htmlFor="recovery-password-verify-input">{t('[recovery] confirm password label')}</Label>
          <Password
            id="recovery-password-verify-input"
            onChange={handleChange}
            name="passwordConf"
            onBlur={handleBlur}
            aria-label="recovery-password-confirm"
            error={touched.passwordConf && errors.passwordConf}
            placeholder={t('[recovery] confirm password placeholder')}
          />
          {touched.passwordConf && errors.passwordConf ? (
            <ErrorText
              onClick={() => {
                console.log(errors)
              }}
            >
              {t('[recovery] confirm password error')}
            </ErrorText>
          ) : null}
        </FormGroup>

        <Button
          className="m-m-b-2"
          aria-label="submit-button"
          type="submit"
          worker={true}
          active={isSubmitting && isLoading}
        >
          {t('[recovery] submit label')}
        </Button>
      </form>
    </div>
  )
}
