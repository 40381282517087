import { Button } from '@gbg/gbgcomponentlibrary_react'
import styles from './403.module.css'

export const FourOhThree = () => {
  return (
    <>
      <h1>Sorry!</h1>
      <p className="heading-small m-m-t-2">But you don’t have permission to access this area.</p>
      <p className="paragraph-medium m-m-t-2 m-m-b-2">Error code: 403</p>
      <Button
        onClick={() => {
          document.location.href = 'https://www.gbgplc.com'
        }}
      >
        Return home
      </Button>
      <br />
      <a
        style={{
          display: 'block',
        }}
        className={`paragraph-large m-m-t-2 ${styles.helpLink}`}
        href="https://www.gbgplc.com/en/contact/customer-support-enquiry/"
      >
        Get help
      </a>
    </>
  )
}
