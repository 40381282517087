import * as yup from 'yup'

export const schema = yup.object().shape({
  recoveryCode: yup.string().required(),
  password: yup.string().required(),
  passwordConf: yup
    .string()
    .required()
    .oneOf([yup.ref('password')]),
})

export default schema
