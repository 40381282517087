import i18n from 'i18next'
import { mergeDeepRight, mergeRight } from 'ramda'
import { initReactI18next } from 'react-i18next'

import { translations as Login } from '../../features/login'
import { translations as Recovery } from '../../features/recovery-code'
import { translations as Forgotten } from '../../features/forgotten-password'
import { translations as ChangePassword } from '../../features/change-password'
import { translations as Username } from '../../features/username'
import { translations as Flow } from '../../features/flow'
import { translations as Error } from '../../features/error'
import { translations as Password } from '../../features/password'
import { default as GoLayout } from '../../features/layouts/GoLayout.intl'
import { default as LocationLayout } from '../../features/layouts/LocationLayout.intl'

const resources = [
  Login,
  Forgotten,
  Recovery,
  ChangePassword,
  Username,
  Password,
  Error,
  Flow,
  GoLayout,
  LocationLayout,
].reduce((a, b) => {
  return mergeDeepRight(a, b)
}, {})

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  lng: process.env.REACT_APP_LANGUAGE || 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
