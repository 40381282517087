import config from './config'

export const authorize = async (redirectUrl: string, isForceAuth: boolean, nonce?: string | undefined) => {
  if (!nonce) nonce = encodeURIComponent(generateRandomValue())
  const authApiUrl =
    `${config.AUTH_API_BASE_URL()}/as/authorization.oauth2?` +
    `client_id=${encodeURIComponent(config.clientId)}` +
    `&scope=${encodeURIComponent(config.scope)}` +
    `&response_type=${encodeURIComponent(config.responseType)}` +
    `&redirect_uri=${encodeURIComponent(redirectUrl)}&nonce=${nonce}` +
    `${isForceAuth ? '&prompt=login' : ''}`

  window.location.replace(authApiUrl)
}

const generateRandomValue = () => {
  const D = new Uint32Array(2)
  window.crypto.getRandomValues(D)
  return D[0].toString(36)
}

export const getURLParameter = <T>(paramName: string) => {
  const urlParts = decomposeUrl(window.location.href)
  return urlParts?.queryParams?.[paramName] as T
}

export const decomposeUrl = (url: string) => {
  if (!url) {
    return {}
  }

  const a = document.createElement('a')
  a.href = url

  return {
    host: a.host,
    pathname: a.pathname,
    search: a.search,
    queryParams: parseQueryParams(a.search),
    hash: a.hash,
  }
}

const parseQueryParams = (searchStr: string): any => {
  return searchStr
    .replace(/^\?/, '')
    .split('&')
    .reduce((prev, item) => {
      return Object.assign({ [item.split('=')[0]]: decodeURIComponent(item.split('=')[1]) }, prev)
    }, {})
}
