/******************************************************************************
 *         PingOne Authentication Flow Actions API
 ******************************************************************************/

import config from '../../app/config'
import Axios from 'axios'
import { FlowRepsonse } from './auth.types'

export const getFlow = async (environmentId: string, flowId: string): Promise<FlowRepsonse> => {
  //const flowUrl = `${Config.AuthDomain}/${environmentId}/flows/${flowId}`
  const flowUrl = `${config.LOGOUT_BASE_URL()}/flows/${flowId}`
  const response = await Axios.get<FlowRepsonse>(flowUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })
  return response.data

  // return get(flowUrl, true, { 'Content-Type': 'application/json' }, true)
}

/**
 *  lookup user
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 * @param username user name
 */
export const lookupUser = (apiPath: string, username: string): Promise<FlowRepsonse> => {
  return pingPost<FlowRepsonse>(apiPath, 'user.lookup+json', { username })
}

/**
 *  Login user
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 * @param username user name
 * @param password user password
 */
export const signOn = (apiPath: string, username: string, password: string): Promise<FlowRepsonse> => {
  return pingPost<FlowRepsonse>(apiPath, 'usernamePassword.check+json', { username, password })
}

/**
 * Recover a user’s forgotten password.
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 * @param username user name
 */
export const forgotPassword = (apiPath: string, username: string): Promise<FlowRepsonse> => {
  return pingPost<FlowRepsonse>(apiPath, 'password.forgot+json', { username })
}

/**
 * Send the OTP to the user. The OTP is a randomly generated eight-character alphanumeric string sent to the user’s email address, and the code is valid for five minutes.
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 */
export const sendRecoveryCode = (apiPath: string) => {
  return pingPost<FlowRepsonse>(apiPath, 'password.sendRecoveryCode+json')
}

/**
 * Recover the account and set a new password.
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 * @param recoveryCode
 * @param newPassword
 */
export const recoverUserPassword = (
  apiPath: string,
  recoveryCode: string,
  newPassword: string,
): Promise<FlowRepsonse> => {
  return pingPost<FlowRepsonse>(apiPath, 'password.recover+json', { recoveryCode, newPassword })
}

/**
 * Change (or reset) the user’s password.
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 * @param username user name
 * @param currentPassword
 * @param newPassword
 */
export const changeUserPassword = (
  apiPath: string,
  currentPassword: string,
  newPassword: string,
): Promise<FlowRepsonse> => {
  return pingPost<FlowRepsonse>(apiPath, 'password.reset+json', { currentPassword, newPassword })
}

/**
 * Update (or reset) a flow orchestration session.
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 */
export const resetFlow = (apiPath: string): Promise<FlowRepsonse> => {
  return pingPost<FlowRepsonse>(apiPath, 'session.reset+json', {})
}

/**
 * Send the user a new account verification email.
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 */
export const sendVerificationCode = (apiPath: string) => {
  return pingPost(apiPath, 'user.sendVerificationCode+json')
}

/**
 * Verify the user account to continue the authentication flow.
 * The user must click the link in the verification email to verify the account. The request body requires the verificationCode attribute identifying the verification code to check.
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 * @param verificationCode
 */
export const verifyUser = (apiPath: string, verificationCode: string) => {
  return pingPost(apiPath, 'user.verify+json', { verificationCode })
}

/**
 * Register a new user.
 * @param apiPath PingOne for Customers authorization and authentication endpoint
 * @param username user name
 * @param email
 * @param password
 */
export const registerUser = (apiPath: string, username: string, email: string, password: string) => {
  return pingPost(apiPath, 'user.register+json', { username, email, password })
}

const pingPost = async <T>(apiPath: string, contentType: string, body = {}) =>
  await post<T>(apiPath, `application/vnd.pingidentity.${contentType}`, body)

const post = async <T>(apiPath: string, contentType: string, body = {}): Promise<T> => {
  try {
    const response = await Axios.post<T>(apiPath, body, {
      withCredentials: true,
      headers: {
        'Content-Type': contentType,
      },
    })
    return response.data
  } catch (error: any) {
    console.log('ping post error', error, error?.response)
    throw error
  }
}
