import en from './locales/en.json'
import fr from './locales/fr.json'

export const NAMESPACE = 'Login'

const translations = {
  en: {
    Login: en,
  },
  fr: {
    Login: fr,
  },
}

export default translations
