import {
  Card,
  CardBody,
  InlineNotification,
  InlineNotificationType,
  Logo,
  LogoKeys,
} from '@gbg/gbgcomponentlibrary_react'
import { props } from 'ramda'
import React from 'react'
import '@gbg/gbgcomponentlibrary/dist/themes/loqate.css'
import '@gbg/gbgcomponentlibrary/dist/themes/loqate2.css'
import { useTranslation } from 'react-i18next'
import { NAMESPACE as tNamespace } from './LocationLayout.intl'

export const LocationLayout = ({ children }: React.PropsWithChildren<{}>) => {
  const { t } = useTranslation(tNamespace)
  return (
    <div className="loqate-switchable-theme loqate2-switchable-theme structure structure--static-modal">
      <div className="page__wrapper">
        <div className="page__header">
          <Logo logo={LogoKeys.Loqate_dark}></Logo>
        </div>
        <div className="page__content">
          <Card isUnbordered={false} style={{ maxWidth: 537 }}>
            <CardBody>
              <InlineNotification className="m-m-b-4" type={InlineNotificationType.Info}>
                <h4 className="m-0 m-m-b-2">{t('[location-layout] Update title')}</h4>
                <p>
                  {t('[location-layout] Update message')}
                  <a href="https://account.loqate.com">{t('[location-layout] Update action')}</a>
                </p>
              </InlineNotification>
              {children}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  )
}
