import { Card, CardBody } from '@gbg/gbgcomponentlibrary_react'
import React from 'react'
import '@gbg/gbgcomponentlibrary/dist/themes/loqate.css'
import '@gbg/gbgcomponentlibrary/dist/themes/loqate2.css'
import '../../go.css'
import { useTranslation } from 'react-i18next'
import { NAMESPACE as tNamespace } from './GoLayout.intl'

export const GoLayout = ({ children }: React.PropsWithChildren<{}>) => {
  const { t } = useTranslation(tNamespace)
  return (
    <div className="loqate-switchable-theme loqate2-switchable-theme go-theme structure structure--static-modal">
      <div className="page__wrapper">
        <div className="page__header">
          <img src="/gbg-go.svg" alt="GBG GO" />
        </div>
        <div className="page__content">
          <Card isUnbordered={false} style={{ maxWidth: 537 }}>
            <CardBody>{children}</CardBody>
          </Card>
        </div>
        <p className="go-footer-text">
          {t('[go-layout] Footer copyright', { year: new Date().getFullYear() })}
          <br />
          <a target="_blank" href="https://www.gbgplc.com/en/legal-and-regulatory/privacy-policy/">
            {t('[go-layout] Footer privacy')}
          </a>{' '}
          {t('[go-layout] Footer and')}{' '}
          <a target="_blank" href="https://www.gbgplc.com/en/legal-and-regulatory/">
            {t('[go-layout] Footer terms')}
          </a>
        </p>
      </div>
    </div>
  )
}
