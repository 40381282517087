import React from 'react'
import schema from './schema'
import { useTranslation } from 'react-i18next'
import styles from './Password.module.css'
import {
  Button,
  ButtonKind,
  ErrorText,
  FormGroup,
  IconKeys,
  Label,
  Password as PasswordDS,
} from '@gbg/gbgcomponentlibrary_react'
import { NAMESPACE as tNamespace } from './Password.intl'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  forgotPasswordAsync,
  resetSessionAsync,
  selectFlow,
  selectLinkUrl,
  selectIsLoading,
  signOnAsync,
} from '../../common/auth/authSlice'
import { useFormik } from 'formik'

export const Password: React.FC = () => {
  const { t } = useTranslation(tNamespace)
  const signOnUrl = useAppSelector(selectLinkUrl('usernamePassword.check'))
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    touched,
    values, // use this if you want controlled components
    errors,
  } = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      dispatch(
        signOnAsync({
          apiPath: signOnUrl,
          username: _flow?._embedded.user.username,
          password: values.password,
        }),
      )
    },
  })
  const isLoading = useAppSelector(selectIsLoading)
  const dispatch = useAppDispatch()

  const onForgotPassword = () => {
    dispatch(
      forgotPasswordAsync({
        apiPath: _flow?._links?.['password.forgot']?.href ?? '',
        username: _flow?._embedded.user.username,
      }),
    )
  }
  const _flow = useAppSelector(selectFlow)
  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h1 className="heading-large m-t-0">{t('[login] login title')}</h1>
        <FormGroup>
          <p className="heading-small">{_flow?._embedded?.user?.username ?? ''}</p>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password-input">{t('[login] login password label')}</Label>
          <PasswordDS
            id="password-input"
            onChange={handleChange}
            name="password"
            onBlur={handleBlur}
            aria-label="password-input"
            error={touched.password && errors.password}
            placeholder={t('[login] login password input placeholder')}
          />
          {touched.password && errors.password ? <ErrorText>Password is required</ErrorText> : null}
          <a href="#" className={`${styles.forgotten} text-b800`} onClick={() => onForgotPassword()}>
            {t('[login] login recover password link')}
          </a>
        </FormGroup>
        <FormGroup className={styles.buttonGroup}>
          <Button
            type="button"
            onClick={() => {
              dispatch(resetSessionAsync({ apiPath: _flow?._links?.['session.reset']?.href ?? '' }))
            }}
            className="m-m-r-4"
            kind={ButtonKind.Secondary}
            iconBefore={IconKeys.ChevronLeft}
            aria-label="submit-button"
          >
            {t('[login] login back label')}
          </Button>
          <Button aria-label="submit-button" type="submit" worker={true} active={isSubmitting && isLoading}>
            {t('[login] login submit label')}
          </Button>
        </FormGroup>
      </form>
    </div>
  )
}
