import { Routes, Route } from 'react-router-dom'
import { FourOhThree } from '../403'
import { InlineNotification } from '@gbg/gbgcomponentlibrary_react'
import Flow from '../flow'
import { InlineNotificationType } from '@gbg/gbgcomponentlibrary_react/build/Molecules/Notifications/InlineNotification/InlineNotification.types'

export const Content = ({
  message,
  pillarCode,
  usr,
}: {
  message: { content: string; type: InlineNotificationType }
  pillarCode: string
  usr?: string
}) => {
  return (
    <Routes>
      <Route path="/403" element={<FourOhThree />} />
      <Route
        path="*"
        element={
          <>
            {message.content != '' ? (
              <InlineNotification type={message.type}>{message.content}</InlineNotification>
            ) : null}
            <Flow usr={usr} pillarCode={pillarCode}></Flow>
          </>
        }
      />
    </Routes>
  )
}
