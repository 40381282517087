import { FormGroup, Label, Password, ErrorText, Policy } from '@gbg/gbgcomponentlibrary_react'
import { FormikErrors, FormikTouched } from 'formik'
import { FC } from 'react'
interface IPasswordInput {
  handleChange: (e: React.ChangeEvent<any>) => void
  handleBlur: (e: React.FocusEvent<any>) => void
  touched: FormikTouched<any>
  errors: FormikErrors<any>
  password: string
  label: string
  placeholder: string
  errorText: string
}
export const PasswordInput: FC<IPasswordInput> = ({
  handleBlur,
  handleChange,
  touched,
  errors,
  password,
  label,
  errorText,
  placeholder,
}) => {
  return (
    <FormGroup>
      <Label htmlFor="password-input">{label}</Label>
      <Password
        id="password-input"
        onChange={handleChange}
        name="password"
        onBlur={handleBlur}
        aria-label="password-input"
        error={touched.password && errors.password}
        placeholder={placeholder}
      />
      {touched.password && errors.password ? <ErrorText>{errorText}</ErrorText> : null}
      <Policy
        value={password}
        items={[
          {
            title: 'One number',
            pattern: '[1-9]',
          },
          {
            title: '8 characters minimum',
            pattern: '^.{8,}$',
          },
          {
            title: 'One symbol',
            pattern: '[@$!%*?&]',
          },
          {
            title: 'One uppercase character',
            pattern: '[A-Z]',
          },
        ]}
      ></Policy>
    </FormGroup>
  )
}
