import { useEffect, useMemo } from 'react'
import './App.css'
import { TopBar, TopBarBrand, Card, InlineNotificationType, CardBody } from '@gbg/gbgcomponentlibrary_react'
import { Pages } from '@gbg/gbgcomponentlibrary'
import Brand from './features/brand/Brand'
import { useTranslation } from 'react-i18next'
import { Content } from './features/content/Content'
import { LocationLayout } from './features/layouts/LocationLayout'
import { GoLayout } from './features/layouts/GoLayout'

function App() {
  if (window.location.href.includes('/login')) {
    return <Brand></Brand>
  }
  const lang = useMemo<string>(() => {
    return localStorage?.lang ?? navigator.language
  }, [])
  const { i18n } = useTranslation()
  i18n.changeLanguage(lang)
  const pillar = useMemo<string>(() => {
    return localStorage?.pillar ?? 'c'
  }, [])
  const usr = useMemo<string | undefined>(() => {
    return localStorage?.usr ?? undefined
  }, [])
  const message = useMemo<any>(() => {
    return {
      content: localStorage?.message ?? '',
      type: localStorage?.messageType ?? InlineNotificationType.Info,
    }
  }, [])
  setTimeout(() => {
    localStorage.removeItem('pillar')
    localStorage.removeItem('message')
    localStorage.removeItem('messageType')
    localStorage.removeItem('usr')
  }, 1)
  let sideClass = ''
  let navClass = ''
  const coloured = pillar.startsWith('c')
  let brand = ''
  const pillarCode: string = pillar.length == 2 ? pillar[1] : pillar
  switch (pillarCode) {
    case 'i':
      brand = 'identity'
      break
    case 'f':
      brand = 'fraud'
      break
    case 'l':
      brand = 'location'
      break
    case 'g':
      brand = 'go'
      break
  }

  sideClass = `structure--stepper${coloured ? '--colored' : ''}${brand != '' ? `--${brand}` : ''}`
  navClass = `page__navigation${coloured ? '--colored' : ''}${brand != '' ? `-${brand}` : ''}`

  if (pillarCode === 'l' && coloured) {
    navClass = `${navClass} page__navigation--colored-location--random`
  }

  useEffect(() => {
    Pages.updateNavBackground()
  }, [pillar])

  // add onetrust to page
  useEffect(() => {
    if (pillarCode != 'l') {
      return
    }

    const head = document.getElementsByTagName('head')[0]

    const jsOneTrustInclude = document.createElement('script')
    const dataDomainScriptId = '6e48ce87-3fce-47d3-b794-cd23e80f5b8f'
    jsOneTrustInclude.type = 'text/javascript'
    jsOneTrustInclude.src = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js'
    jsOneTrustInclude.charset = 'UTF-8'
    jsOneTrustInclude.async = true
    jsOneTrustInclude.setAttribute('data-domain-script', dataDomainScriptId)

    const jsOptanon = document.createElement('script')
    jsOptanon.type = 'text/javascript'
    jsOptanon.text = 'function OptanonWrapper() { }'

    head.appendChild(jsOneTrustInclude)
    head.appendChild(jsOptanon)
  }, [pillarCode])

  const lightLogo = coloured

  if (pillarCode === 'l') {
    return (
      <LocationLayout>
        <Content usr={usr} message={message} pillarCode={pillarCode} />
      </LocationLayout>
    )
  }

  if (pillarCode === 'g') {
    return (
      <GoLayout>
        <Content usr={usr} message={message} pillarCode={pillarCode} />
      </GoLayout>
    )
  }

  return (
    <div className={`structure structure--stepper ${sideClass}`}>
      <TopBar
        brand={TopBarBrand.GBG}
        lightLogo={lightLogo}
        includeUser={false}
        user={{ username: '', company: '' }}
        includeNavLink={false}
        menu={null}
      ></TopBar>
      <div className={`page__navigation ${navClass}`} style={{}}></div>
      <div className="page__content">
        <Card isPrimaryContent={true}>
          <CardBody className="p-m-t-10" isPrimaryContent={true}>
            <Content usr={usr} message={message} pillarCode={pillarCode} />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default App
