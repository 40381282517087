import React, { useEffect } from 'react'
import schema from './schema'
import { useTranslation } from 'react-i18next'
import styles from './Username.module.css'
import { Button, ErrorText, FormGroup, Label, Text } from '@gbg/gbgcomponentlibrary_react'
import { NAMESPACE as tNamespace } from './Username.intl'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { provideUsernameAsync, selectLinkUrl, selectMessage, selectIsLoading } from '../../common/auth/authSlice'
import { useFormik } from 'formik'

export const Username: React.FC<{ pillarCode?: string; usr?: string }> = ({ pillarCode, usr }) => {
  const { t } = useTranslation(tNamespace)
  const provideUsernameUrl = useAppSelector(selectLinkUrl('user.lookup'))
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    touched,
    values, // use this if you want controlled components
    errors,
    submitForm,
  } = useFormik({
    initialValues: {
      username: usr ?? '',
    },
    validationSchema: schema,
    onSubmit: values => {
      dispatch(
        provideUsernameAsync({
          apiPath: provideUsernameUrl,
          username: values.username.toLowerCase(),
        }),
      )
    },
  })
  const _message = useAppSelector(selectMessage)
  const isLoading = useAppSelector(selectIsLoading)
  const dispatch = useAppDispatch()
  //Have to update links based on pillars when available
  const signUpURL = 'https://app-us.platform.loqate.com/sign-up/'
  useEffect(() => {
    if (usr) {
      submitForm()
    }
  }, [usr])
  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.form}>
        {_message.isError ? <h1>Error</h1> : null}
        <h1 className="heading-large m-t-0">{t('[username] username title')}</h1>
        {pillarCode == 'l' ? (
          <div className={styles.horizontaldiv}>
            <p className={styles.paragraph}>{t('[username] dont have an account')}&nbsp;</p>
            <a
              href="#"
              onClick={() => {
                window.location.assign(signUpURL)
              }}
            >
              {t('[username] Sign up')}
            </a>
            <p className={styles.paragraph}>.</p>
          </div>
        ) : (
          <></>
        )}
        <FormGroup>
          <Label htmlFor="username-input">{t('[username] username email label')}</Label>
          <Text
            id="username-input"
            type="text"
            name="username"
            error={touched.username && errors.username}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('[username] username email input placeholder')}
            aria-label="username-input"
          />
          {touched.username && errors.username ? <ErrorText>{t('[username] username email error')}</ErrorText> : null}
        </FormGroup>

        <Button
          className="m-m-t-4"
          aria-label="submit-button"
          type="submit"
          worker={true}
          active={isSubmitting && isLoading}
        >
          {t('[username] username submit label')}
        </Button>
      </form>
    </div>
  )
}
