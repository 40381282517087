import React from 'react'
import schema from './schema'
import { useTranslation } from 'react-i18next'
import styles from './ChangePassword.module.css'
import { Button, ErrorText, FormGroup, Label, Password, Policy, Text } from '@gbg/gbgcomponentlibrary_react'
import { NAMESPACE as tNamespace } from './ChangePassword.intl'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectMessage, changeUserPasswordAsync, selectLinkUrl, selectIsLoading } from '../../common/auth/authSlice'
import { useFormik } from 'formik'
import { PasswordInput } from '../password/PasswordInput'

export const ChangePassword: React.FC = () => {
  const { t } = useTranslation(tNamespace)
  const changePasswordUrl = useAppSelector(selectLinkUrl('password.reset'))
  const dispatch = useAppDispatch()
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    touched,
    values, // use this if you want controlled components
    errors,
  } = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      passwordConfirm: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      const { oldPassword, password } = values
      dispatch(
        changeUserPasswordAsync({
          apiPath: changePasswordUrl,
          currentPassword: oldPassword,
          newPassword: password,
        }),
      )
    },
  })
  const _message = useAppSelector(selectMessage)
  const isLoading = useAppSelector(selectIsLoading)

  return (
    <div className={styles.container}>
      <h1 className="heading-large m-t-0">{t('[change] title')}</h1>
      <p className="paragraph-medium">{t('[change] intro')}</p>
      <form onSubmit={handleSubmit} className={styles.form}>
        {_message.isError ? <h1>Error</h1> : null}

        <FormGroup className="m-m-t-4 m-m-b-4">
          <Label htmlFor="old-password">{t('[change] old password label')}</Label>
          <Password
            id="old-password"
            onChange={handleChange}
            name="oldPassword"
            onBlur={handleBlur}
            aria-label="old-password"
            error={touched.oldPassword && errors.oldPassword}
            placeholder={t('[change] old password placeholder')}
          />
          {touched.oldPassword && errors.oldPassword ? <ErrorText>{t('[change] old password error')}</ErrorText> : null}
        </FormGroup>
        <PasswordInput
          label={t('[change] password label')}
          errorText={t('[change] password error')}
          placeholder={t('[change] password placeholder')}
          {...{ handleBlur, handleChange, password: values.password, touched, errors }}
        />
        <FormGroup className="m-m-t-4 m-m-b-4">
          <Label htmlFor="password-verify-input">{t('[change] confirm password label')}</Label>
          <Password
            id="password-verify-input"
            onChange={handleChange}
            name="passwordConfirm"
            onBlur={handleBlur}
            aria-label="password-input"
            error={touched.passwordConfirm && errors.passwordConfirm}
            placeholder={t('[change] confirm password placeholder')}
          />
          {touched.passwordConfirm && errors.passwordConfirm ? (
            <ErrorText>{t('[change] confirm password error')}</ErrorText>
          ) : null}
        </FormGroup>

        <Button
          className="m-m-b-2"
          aria-label="submit-button"
          type="submit"
          worker={true}
          active={isSubmitting && isLoading}
        >
          {t('[change] submit label')}
        </Button>
      </form>
    </div>
  )
}
