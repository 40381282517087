export enum FlowStatus {
  SUCCESS = 'SUCCESS',
  UNAUTHORIZED = 'UNAUTHORIZED',
  COMPLETED = 'COMPLETED',
  USERNAME_PASSWORD_REQUIRED = 'USERNAME_PASSWORD_REQUIRED',
  PASSWORD_REQUIRED = 'PASSWORD_REQUIRED',
  SIGN_ON_REQUIRED = 'SIGN_ON_REQUIRED',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  NO_PASSWORD = 'NO_PASSWORD',
  PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
  PASSWORD_LOCKED_OUT = 'PASSWORD_LOCKED_OUT',
  MUST_CHANGE_PASSWORD = 'MUST_CHANGE_PASSWORD',
  PASSWORD_REQUIREMENTS_NOT_MET = 'PASSWORD_REQUIREMENTS_NOT_MET',
  VERIFICATION_CODE_REQUIRED = 'VERIFICATION_CODE_REQUIRED',
  RECOVERY_CODE_REQUIRED = 'RECOVERY_CODE_REQUIRED',
  INVALID_VALUE = 'INVALID_VALUE',
  UNIQUENESS_VIOLATION = 'UNIQUENESS_VIOLATION',
  UNKNOWN = 'UNKNOWN',
  FAILED = 'FAILED',
  EXTERNAL_AUTHENTICATION_REQUIRED = 'EXTERNAL_AUTHENTICATION_REQUIRED',
}

export enum RequiredProperties {
  MAX_REPEATED_CHARACTERS = 'maxRepeatedCharacters',
  MIN_UNIQUE_CHARACTERS = 'minUniqueCharacters',
  LENGTH = 'length',
  MIN_CHARACTERS = 'minCharacters',
  MIN_LOWERCASE = 'abcdefghijklmnopqrstuvwxyz',
  MIN_UPPERCASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  MIN_NUMERIC = '0123456789',
  MIN_SPECIAL = '~!@#$%^&*()-_=+[]{}|; =,.<>/?',
  MIN_COMPLEXITY = 'minComplexity',
}

export interface FlowRepsonse {
  _links: Record<string, Link>
  id: string
  resumeUrl: string
  error?: {
    code: string
    message: string
    secondsUntilUnlock?: number
  }
  status: FlowStatus
  createdAt: Date
  expiresAt: Date
  _embedded: Embedded
}

interface Embedded {
  application: Application
  passwordPolicy?: PasswordPolicy
  user?: any
  socialProviders?: string[]
  identityProvider?: { _links: { authenticate?: { href: string } } }
}

interface PasswordPolicy {
  name: string
}

interface Application {
  name: string
  icon?: Icon
  homePageUrl?: string
}

interface Icon {
  id: string
  href: string
}

interface Link {
  href: string
}

export class Flow {
  id: string
  links: Record<string, Link>
  embedded: Embedded
  status: FlowStatus
  resumeUrl: string

  constructor(fr: FlowRepsonse) {
    const { id, status, _links, _embedded, resumeUrl } = fr
    this.id = id
    this.links = _links
    this.embedded = _embedded
    this.status = status
    this.resumeUrl = resumeUrl
  }

  isPasswordExpired() {
    return this.status == FlowStatus.PASSWORD_EXPIRED
  }

  isCompleted() {
    return this.status == FlowStatus.COMPLETED
  }

  isFailed() {
    return this.status == FlowStatus.FAILED
  }

  isRecoveryCodeRequired() {
    return this.status == FlowStatus.RECOVERY_CODE_REQUIRED
  }

  getLinks() {
    return this.links
  }

  getPasswordPolicy() {
    return this.embedded.passwordPolicy
  }

  getUser() {
    return this.embedded.user
  }

  getSocialProviders() {
    return this.embedded.socialProviders
  }
}
