import en from './locales/en.json'
import fr from './locales/fr.json'

export const NAMESPACE = 'Flow'

const translations = {
  en: {
    Flow: en,
  },
  fr: {
    Flow: fr,
  },
}

export default translations
