import React, { useEffect } from 'react'
import { authorize, getURLParameter } from '../../app/helpers'
import { InlineNotificationType } from '@gbg/gbgcomponentlibrary_react'
import { useTranslation } from 'react-i18next'

export const Brand: React.FC<any> = () => {
  const { i18n } = useTranslation()
  const pillar = (getURLParameter<string>('p') ?? 'c').toLowerCase()
  const usr = (getURLParameter<string>('u') ?? '').toLowerCase()
  const lang = (getURLParameter<string>('l') ?? navigator.language).toLowerCase()
  i18n.changeLanguage(lang)
  const message = getURLParameter<string>('m') ?? ''
  const messageType = getURLParameter<string>('mt') ?? InlineNotificationType.Info
  localStorage.setItem('usr', usr)
  localStorage.setItem('lang', lang)
  localStorage.setItem('message', message)
  localStorage.setItem('messageType', messageType)
  localStorage.setItem('pillar', pillar)
  localStorage.setItem('lastSetup', JSON.stringify({ message, messageType, pillar }))
  useEffect(() => {
    async function go() {
      await authorize(
        getURLParameter<string>('redirect_url') ?? 'c',
        getURLParameter<boolean>('forceAuth'),
        getURLParameter<string>('nonce'),
      )
    }
    setTimeout(() => {
      go()
    }, 1)
  }, [])
  return <p>Redirecting...</p>
}

export default Brand
