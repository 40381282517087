import React from 'react'
import { getURLParameter } from '../../app/helpers'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { FlowStatus, Flow as aFlow } from '../../common/auth/auth.types'
import { selectFlow, getFlowAsync, selectMessage } from '../../common/auth/authSlice'
import Login from '../login'
import Username from '../username'
import RecoveryCode from '../recovery-code'
import ChangePassword from '../change-password'
import Error from '../error'
import Password from '../password'
import { InlineNotification, InlineNotificationType } from '@gbg/gbgcomponentlibrary_react'
import { useTranslation } from 'react-i18next'
import { NAMESPACE as tNamespace } from './Flow.intl'

export const Flow: React.FC<{ pillarCode?: string; usr?: string }> = ({ pillarCode, usr }) => {
  const _flow = useAppSelector(selectFlow)
  const dispatch = useAppDispatch()
  const message = useAppSelector(selectMessage)
  const environmentId = getURLParameter<string>('environmentId')
  const flowId = getURLParameter<string>('flowId')
  const { t } = useTranslation(tNamespace)
  if (!_flow && !message.isError) {
    dispatch(
      getFlowAsync({
        environmentId: environmentId,
        flowId: flowId,
      }),
    )
  }

  if (_flow) {
    const flow = new aFlow(_flow)
    if (flow.isCompleted()) {
      window.location.assign(flow.resumeUrl)
      window.history.replaceState({}, '', '#done')
      return null
    }
  }

  return (
    <div>
      {message.isError ? (
        <>
          <InlineNotification type={InlineNotificationType.Error}>
            <p>{message.content}</p>
            {message.data ? (
              <>
                <h5 className="m-b-0">{t('[flow] error details')}</h5>
                <pre className="m-b-0" style={{ whiteSpace: 'break-spaces' }}>
                  {JSON.stringify(message.data, null, 2)}
                </pre>
              </>
            ) : null}
          </InlineNotification>
        </>
      ) : null}
      {_flow?.status == FlowStatus.FAILED ? <Error flow={_flow} /> : null}
      {_flow?.status == FlowStatus.SIGN_ON_REQUIRED ? <Username usr={usr} pillarCode={pillarCode} /> : null}
      {_flow?.status == FlowStatus.USERNAME_PASSWORD_REQUIRED ? <Login /> : null}
      {_flow?.status == FlowStatus.PASSWORD_REQUIRED ? <Password /> : null}
      {_flow?.status == FlowStatus.RECOVERY_CODE_REQUIRED ? <RecoveryCode /> : null}
      {_flow?.status == FlowStatus.MUST_CHANGE_PASSWORD ? <ChangePassword /> : null}
    </div>
  )
}

export default Flow
