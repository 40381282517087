import React from 'react'
import schema from './schema'
import { useTranslation } from 'react-i18next'
import styles from './Login.module.css'
import {
  Button,
  Checkbox,
  ErrorText,
  FormGroup,
  FormGroupInfo,
  FormGroupType,
  Label,
  LabelText,
  Password,
  Text,
} from '@gbg/gbgcomponentlibrary_react'
import { NAMESPACE as tNamespace } from './Login.intl'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectLinkUrl, selectMessage, selectIsLoading, signOnAsync } from '../../common/auth/authSlice'
import { useFormik } from 'formik'

export const Login: React.FC = () => {
  const { t } = useTranslation(tNamespace)
  const signOnUrl = useAppSelector(selectLinkUrl('usernamePassword.check'))
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    touched,
    values, // use this if you want controlled components
    errors,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      dispatch(
        signOnAsync({
          apiPath: signOnUrl,
          username: values.email.toLowerCase(),
          password: values.password,
        }),
      )
    },
  })
  const _message = useAppSelector(selectMessage)
  const isLoading = useAppSelector(selectIsLoading)
  const dispatch = useAppDispatch()

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.form}>
        {_message.isError ? <h1>Error</h1> : null}
        <h1 className="heading-large m-t-0">{t('[login] login title')}</h1>
        <FormGroup>
          <Label htmlFor="email-input">{t('[login] login email label')}</Label>
          <Text
            id="email-input"
            type="email"
            name="email"
            error={touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('[login] login email input placeholder')}
            aria-label="email-input"
          />
          {touched.email && errors.email ? <ErrorText>{t('[login] login email error')}</ErrorText> : null}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password-input">{t('[login] login password label')}</Label>
          <Password
            id="password-input"
            onChange={handleChange}
            name="password"
            onBlur={handleBlur}
            aria-label="password-input"
            error={touched.password && errors.password}
            placeholder={t('[login] login password input placeholder')}
          />
          {touched.password && errors.password ? <ErrorText>Password is required</ErrorText> : null}
        </FormGroup>
        <a
          className="text-b800"
          onClick={() => {
            console.log('TODO: recover')
          }}
        >
          {t('[login] login recover password link')}
        </a>
        <FormGroup type={FormGroupType.Checkbox}>
          <Checkbox />
          <FormGroupInfo>
            <Label>
              <LabelText>{t('[login] login remember me label')}</LabelText>
            </Label>
          </FormGroupInfo>
        </FormGroup>
        <Button className="m-m-t-4" aria-label="submit-button" worker={true} active={isSubmitting && isLoading}>
          {t('[login] login submit label')}
        </Button>
      </form>
    </div>
  )
}
